import React from 'react';
import PropTypes from 'prop-types';

const ReferenceCode = ({ content, readableReference }) => (
	<div className="mt-8">
		<div className="px-4 py-8 my-0 text-center bg-white">
			<p className="text-base-f mb-xxs-f">{content}</p>
			<p className="text-blue-400 text-2xl-f" id="refCode">
				{readableReference}
			</p>
		</div>
	</div>
);

ReferenceCode.defaultProps = {
	content:
		'Give this reference code to the advisor so they can identify your query topic.',
};

ReferenceCode.propTypes = {
	content: PropTypes.string,
	readableReference: PropTypes.string.isRequired,
};

export { ReferenceCode };
