import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import ShortUniqueId from 'short-unique-id';
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { Head } from '../components/templates/head';
import { NextStepHeader } from '../components/organisms/next-step-header';
import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';
import { PhoneNumber } from '../components/atoms/phone-number';
import { ReferenceCode } from '../components/atoms/reference-code';

import { filterAnswersForSubmission } from '../utils';
import { useSalesforceApi } from '../hooks/salesforce';

import LeftArrowIcon from '../assets/left-arrow.svg';
import RestartIcon from '../assets/restart.svg';

// markup
const CallPage = ({ location }) => {
	const uid = new ShortUniqueId({ length: 8 });
	const reference = uid();
	const readableReference = `${reference.slice(0, 4)}-${reference.slice(4)}`;

	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	const handleBackTracking = () => {
		const hasGtag = typeof window.gtag === 'function';

		if (!hasGtag) return;

		window.gtag('event', 'click', {
			click_text: 'Back',
			click_type: 'button',
		});
	};

	const RecaptchaWrapper = () => {
		const { fetchSalesforce } = useSalesforceApi();

		const answers = location.state ? location.state.answers : null;

		const { executeRecaptcha } = useGoogleReCaptcha();

		// eslint-disable-next-line react-hooks/exhaustive-deps
		useEffect(async () => {
			if (!executeRecaptcha) {
				return;
			}
			const payloadData = {
				answers: filterAnswersForSubmission(answers),
				reference: readableReference,
				details: { selectedDate: '1999-01-01', contactType: 'Call-in' },
			};
			const response = await fetchSalesforce(
				executeRecaptcha,
				payloadData,
				'POST'
			);

			switch (response.status) {
				case 200:
					break;
				case 403:
					navigate('/error', {
						state: {
							errorMessage:
								'Unfortunately, we’ve not been able to verify your Captcha and are unable to submit your form.',
						},
					});
					break;
				case 405:
					navigate('/error');
					break;
				case 500:
					navigate('/error');
					break;
				default:
					navigate('/error');
					break;
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [executeRecaptcha]);

		return <></>;
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head title={site.siteMetadata.title} />
			<main id="main" className="container mx-auto bg-brand-pale-300">
				<NextStepHeader
					title="Call us now"
					subtitle="Get urgent support to keep your business on the right side of the law."
					backBtn={
						<Button
							variant="White"
							onClick={() => {
								handleBackTracking();
								if (location?.state?.backRoute) {
									return navigate(location.state.backRoute);
								}
								return navigate('/answers', {
									state: location.state,
								});
							}}
							className="flex items-center !py-xxs-f !px-l-f"
						>
							<LeftArrowIcon className="mr-2 max-w-none" />
							Back
						</Button>
					}
					restartBtn={
						<CloseButton
							className="!py-xxs-f !px-l-f"
							destination="/"
							promptTitle="Restart Triage"
							promptDesc="Are you sure you want to restart? You will lose all information added so far."
							id="callRestart"
						>
							<RestartIcon className="mr-2" />
							Restart
						</CloseButton>
					}
				/>
				<div className="px-4">
					<div className="mx-auto wrapper-small">
						<PhoneNumber />
						<RecaptchaWrapper />
						<ReferenceCode readableReference={readableReference} />
					</div>
				</div>
			</main>
		</GoogleReCaptchaProvider>
	);
};

CallPage.defaultProps = {
	location: undefined,
};

CallPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	location: PropTypes.object,
};

export default CallPage;
